import React from 'react';
import OnlineCard from './online-card';
import roomData from './online-data';

const OnlineSolutions = () => {
  return (
    <div className="container pt-40 mb-20" id="online">
      <h2 className="uppercase text-center text-4xl md:text-5xl mb-16">
        ONLINE MEGOLDÁSOK
      </h2>
      <div className="md:flex">
        <div className="md:w-1/2 p-5 md:mt-20 lg:mt-10">
          <p className="max-w-lg lg:px-20 mx-auto">
            A virtuális tér határtalan lehetőségeket rejteget a tematikák
            tekintetében, így bármilyen egyedi igényt meg tudunk valósítani. Az
            alap játékunk ideális arra, hogy a csapatok megtapasztalják az
            online csapatépítők hangulatát, mi pedig megismerjük őket. Az első
            játék során a csapatról, a csapat működésével kapcsolatban szerzett
            tapasztalataink alapján specifikusan tudunk további fejlesztő
            programokat kínálni.
          </p>
        </div>
        <div className="md:w-1/2 lg:flex lg:flex-row justify-center">
          <div className="flex flex-col lg:mt-20">
            {roomData.slice(0, 2).map(content => (
              <OnlineCard key={content.description} content={content} />
            ))}
          </div>
          <div className="flex flex-col">
            {roomData.slice(2, 4).map(content => (
              <OnlineCard key={content.description} content={content} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineSolutions;
