import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import ScrollTo from '../layout/scroll-to';

const CompanyHero = () => {
  const images = useStaticQuery(graphql`
    query {
      team: file(relativePath: { regex: "/company/company-team.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      team2: file(relativePath: { regex: "/company/company-team2.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      sustainability: file(
        relativePath: { regex: "/company/sustainability.jpg/" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className="container mt-32 mb-20 md:mb-32">
      <h2 className="uppercase text-center mb-16">Vállalatoknak</h2>
      <div className="w-full lg:flex relative mb-20 overflow-hidden">
        <div className="mt-20">
          <div className="max-w-md mx-auto pl-5 pb-5 pr-5 md:pr-0 xl:mr-24 xl:ml-16">
            <h4 className="text-2xl italic font-medium mb-6">Vállalatok</h4>
            <p className="text-base text-left leading-loose ml-1">
              Sikerélményt viszünk olyan szervezetek életébe is, amelyek a
              szabadulószobán keresztül szeretnének{' '}
              <span className="font-bold">
                nem hétköznapi élményt adni a kollégáknak, partnereknek.
              </span>{' '}
              A szabadulószobára sokan csupán játékként gondolnak, de ezen túl
              széles körben használható a szabadulószoba módszertana, az{' '}
              <span className="font-bold">új kollégák kiválasztására, </span>
              beléptetés utáni <span className="font-bold">képzésére</span>,
              csapatok közötti{' '}
              <span className="font-bold">kommunikáció javítására</span> vagy
              akár az elköteleződés növelésére is.
            </p>
          </div>
          <div className="text-center lg:text-left lg:ml-24 xl:ml-20 mb-20">
            <ScrollTo
              to="mobil"
              className="text-center tracking-wider font-bold uppercase py-4 px-8 border-2 border-primary rounded-full inline-block m-4 min-w-64 lg:ml-0 mx-auto transition duration-200 ease-in-out transform hover:-translate-y-1 bg-transparent text-primary hover:text-orange:600 mr-8 cursor-pointer"
            >
              mobil JÁTÉKAINK
            </ScrollTo>
            <ScrollTo
              to="online"
              className="text-center tracking-wider font-bold uppercase py-4 px-8 border-2 border-primary rounded-full inline-block m-4 min-w-64 lg:ml-0 mx-auto transition duration-200 ease-in-out transform hover:-translate-y-1 bg-transparent text-primary hover:text-orange:600 cursor-pointer"
            >
              ONLINE MEGOLDÁSOK
            </ScrollTo>
          </div>
        </div>
        <div className="lg:w-1/2 ">
          <div className="max-w-md mx-auto relative mb-10">
            <img
              alt="Safe"
              src="/icons/safe.svg"
              width="100"
              className="absolute z-10 bottom-0 right-0 -mr-32"
            />
            <Img
              className=""
              fluid={images.team.childImageSharp.fluid}
              alt="Play"
            />
          </div>
          <div className="relative max-w-md mx-auto lg:mr-0">
            <img
              alt="Trophy"
              src="/icons/trophy.svg"
              width="100"
              className="absolute z-10 top-0 left-0 xl:-ml-32 md:-ml-24"
            />
            <Img
              className=""
              fluid={images.team2.childImageSharp.fluid}
              alt="Play"
            />
          </div>
        </div>
      </div>
      <div className="w-full lg:flex flex-row-reverse relative mb-20">
        <div className="lg:w-1/2 mt-20">
          <div className="max-w-lg mx-auto pl-5 pb-5 pr-5 xl:mr-24 xl:ml-16">
            <h4 className="text-2xl italic font-medium mb-6">
              Fenntarthatóság
            </h4>
            <p className="mb-5 text-base text-left leading-loose px-1">
              Bevételi forrásunk a támogatások és a pályázatok mellett a
              szabadulószobánk is: amikor nem a fiataloknál tartunk programot,
              akkor piaci alapon cégek részére viszünk élményt. A módszer szinte
              ugyanaz, csak a tematikát szabjuk át a cégek igényeire.{' '}
              <span className="font-bold">
                Az ebből befolyó bevételt fordítjuk arra, hogy a
                célcsoportunkhoz el tudjuk vinni az élményt
              </span>{' '}
              és folyamatosan új játékokat tudjunk fejleszteni nekik.
            </p>
            <p className="mb-10 text-base text-left leading-loose px-1">
              Minden egyes céges kitelepülés ára tartalmaz legalább egy
              célcsoporthoz történő kitelepülést is. A modellnek köszönhetően
              egy láthatatlan, de annál fontosabb{' '}
              <span className="font-bold">
                kapcsolatot hozunk létre a for- és non-profit szektor között.
              </span>
            </p>
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="relative max-w-lg mx-auto lg:ml-0 md:pr-20">
            <Img
              className=""
              fluid={images.sustainability.childImageSharp.fluid}
              alt="Play"
            />
          </div>
          <div className="max-w-md mx-auto relative" />
        </div>
      </div>
    </div>
  );
};
export default CompanyHero;
