import React from 'react';
import Button from '../layout/button';

const CompanyOnlineGame = () => {
  return (
    <div className="container md:flex md:flex-row py-20">
      <div className="max-w-lg  xl:pr-32">
        <div className="bg-bgsecond md:rounded-xl max-w-lg md:m-5 p-5 pt-10 xl:p-10 xl:pt-16 xl:ml-24">
          <div className="flex flex-row">
            <h4 className="text-2xl italic font-medium">
              Vállalati online játék
            </h4>
            <img
              src="icons/building.svg"
              alt="building"
              className="-mt-3 pl-2"
            />
          </div>
          <p className="mt-3 max-w-lg">
            A népszerű szabadulószobák koncepciójára létrehozott Online küldetés
            virtuális csapatépítő programunk ideális cégek számára. Az egyedi
            igények figyelembevétele mellett maradandó élményt nyújtunk: kreatív
            és egyben innovatív feladatokkal sikerélményhez juttatjuk a
            játékosokat.
          </p>
          <Button to="/kapcsolat" variant="primary" className="mt-8">
            LÉPJ KAPCSOLATBA VELÜNK!
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyOnlineGame;
