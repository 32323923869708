export default [
  {
    img: 'pergamen',
    width: '80',
    description: 'Virtuális küldetés, általunk biztosított előzetes kiképzéssel'
  },
  {
    img: 'alarm',
    width: '80',
    description: 'A játék kb. 2 órát vesz igénybe (lehet hosszabb is)'
  },
  {
    img: 'couple',
    width: '80',
    description: 'Minimum 4, maximum 20 játékos'
  },
  {
    img: 'battery',
    width: '80',
    description:
      'Energizáló programokat is vállalunk akár két meeting között is.'
  }
];
