import React from 'react';

const Games = () => {
  return (
    <div className="bg-bgfirst mt-20">
      <h2 className="uppercase text-center pt-10">
        MI FEJLŐDIK A JÁTÉK SORÁN?
      </h2>
      <div className="container flex flex-wrap md:flex-row justify-center pb-10">
        <div className="m-5 w-2/4 md:w-1/3  lg:w-1/6 text-center mx-auto">
          <img
            alt="Kalap"
            src="/icons/unit.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Alkalmazkodó készség</p>
        </div>
        <div className="m-5 w-2/4 md:w-1/3 lg:w-1/6 text-center mx-auto">
          <img
            alt="Puzzle"
            src="/icons/idea.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Kreativitás</p>
        </div>
        <div className="m-5 w-2/4 md:w-1/3  lg:w-1/6  text-center mx-auto">
          <img
            alt="Photos"
            src="/icons/conference.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Csapatmunka</p>
        </div>
        <div className="m-5 w-2/4 md:w-1/3 lg:w-1/6  text-center mx-auto">
          <img
            alt="Mozgó"
            src="/icons/bullseye.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Koncentráció</p>
        </div>
        <div className="m-5 w-2/4 md:w-1/3 lg:w-1/6  text-center mx-auto">
          <img
            alt="Mozgó"
            src="/icons/decision.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Memória</p>
        </div>
        <div className="m-5 w-2/4 md:w-1/3 lg:w-1/6  text-center mx-auto">
          <img
            alt="Mozgó"
            src="/icons/clock2.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Reakcióidő</p>
        </div>
      </div>
    </div>
  );
};

export default Games;
