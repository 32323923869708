import React from 'react';
import Button from '../layout/button';

const CommentGreen = ({ description, buttonText, buttonHref }) => {
  return (
    <div className="w-full bg-bgsecond text-center mt-40 p-5 md:p-10 xl:p-20">
      <p className="italic text-2xl max-w-xl xl:px-40 mx-auto leading-relaxed">
        {description}
      </p>
      <Button variants="primary" to={buttonHref} className="mt-16">
        {buttonText}
      </Button>
    </div>
  );
};

export default CommentGreen;
