import React from 'react';
import Layout from '../components/layout';
import Games from '../components/company/games';
import CompanyHero from '../components/company/company-hero';
import OnlineSolutions from '../components/company/online-solutions';
import EarlierGames from '../components/company/earlier-games';
import CompanyReferences from '../components/company/company-references';
import MobilGame from '../components/company/mobil-game';
import CompanyOnlineGame from '../components/company/company-online-game';

const VallalatoknakPage = () => {
  return (
    <Layout
      seo={{
        title: 'Vállalatoknak',
        description: 'A Fortély-csapat'
      }}
    >
      <CompanyHero />
      <Games />
      <MobilGame />
      <EarlierGames />
      <OnlineSolutions />
      <CompanyOnlineGame />
      <CompanyReferences />
    </Layout>
  );
};

export default VallalatoknakPage;
