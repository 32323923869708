import React from 'react';

const CommentYellow = ({ description }) => {
  return (
    <div className="container mx-auto">
      <div className="bg-bgfirst max-w-lg relative text-center mx-auto p-5 py-16 md:p-10 xl:p-20 rounded-xl">
        <img
          alt="quote"
          src="/icons/quote-orange.svg"
          width="100"
          className="absolute z-10 top-0 right-0 -mt-10 mr-5"
        />
        <p className="italic text-2xl leading-relaxed">{description}</p>
        <img
          alt="quote"
          src="/icons/quote-orange-rotate.svg"
          width="100"
          className="absolute z-10 bottom-0 left-0 -mb-10 ml-5"
        />
      </div>
    </div>
  );
};

export default CommentYellow;
