import React from 'react';

const MobilGame = () => {
  return (
    <div className="bg-bgsecond" id="mobil">
      <h2 className="uppercase text-center pt-20 mb-16">mobil JÁTÉKAINK</h2>
      <div className="container md:flex justify-center pb-20">
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/earth.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">E(CO)SCAPE</h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-10 md:mb-10">
            Az E(co)scape a környezettudatosságra épült, hogy a kipróbálok
            számára olyan tippeket adjon, mely tudatosabbá teszi őket
            otthonunkban és munkahelyükön is. Ezzel a cég hozzájárul a kollégák
            szemlélet váltásához.
          </p>
        </div>
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/support.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">
            Megrendelőre szabott megoldás
          </h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-10 ">
            Egyszeri alkalommal is a cégre tudjuk szabni a játékot, legyen szó
            születésnapról, új termék bevezetéséről vagy családi napról. Ebben
            az esetben a játék az előre megebszélt téma alapján épül fel.
          </p>
        </div>
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/memo.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">SAJÁT SZABADULÓSZOBA</h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-10 ">
            Készítünk megrendelésre egyedi játékot, aminek a használati jogát
            átadjuk és külön képzést biztosítunk a mobil szabadulószobák
            üzemeltetéséhez, valamint a későbbiekben is rendelkezésre állunk a
            felmerülő kérdések, problémák kapcsán.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobilGame;
