import React from 'react';

const EarlierGames = () => {
  return (
    <div className="bg-bgfirst">
      <h2 className="uppercase text-center pt-20 mb-16">
        KORÁBBI MOBIL JÁTÉKAINK
      </h2>
      <div className="container md:flex justify-center pb-20">
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/lockinside.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">IT BIZTONSÁG</h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-0 md:mb-10">
            Szinte minden témát meg lehet fogni oly módon, hogy egy szabadulós
            játék legyen belőle, ez alól az IT biztonság sem kivétel. A
            felhasználók által elkövetett “legnépszerűbb” hibákra fókuszáltunk a
            játék során, amire az ezt követő beszélgetésen/képzésen lehetett
            építeni.
          </p>
        </div>
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/collaboration.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">HR TÁMOGATÁS</h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-0 ">
            “Ha kijutsz a szobából, akkor eljöhetsz hozzánk állásinterjúra”, ez
            volt annak a cégnek az ajánlata, akinek készítettük a játékot.
            Állásbörzén ideális volt a játék, mivel valós szituációba kerültek a
            jelentkezők és tétje is volt a játéknak.
          </p>
        </div>
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/business.svg"
            width="106"
            className="mx-auto mt-8 mb-8"
          />
          <h3 className="uppercase text-center mb-5">IPARÁGI TEMATIKA</h3>
          <p className="text-center text-sm px-8 pb-8 md:pb-0 ">
            Műszakváltás között egy kis játék belefér, nem? A megbízó cégnél
            valami hasonló kérdés merülhetett fel, mivel az ott dolgozó kollégák
            műszakváltás után közösen játszhattak a cég profiljára alakított
            játékkal.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EarlierGames;
