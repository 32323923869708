import React from 'react';
import CommentYellow from './comment-yellow';
import CommentGreen from './comment-green';

const CompanyReferences = () => {
  return (
    <div className="py-20">
      <CommentYellow description="Tipikus mintapéldája a win-win helyzetnek… Azzal, hogy te élményt kapsz, másoknak lehetőséget adsz. Ez tényleg az a tér, ahol mindenki nyer. Ha rám hallgatsz, olyan csapatépítőt választasz, ahol mások arcára is mosolyt csalhatsz." />
      <CommentGreen
        description="Különleges csapatépítő programot keresel? Szeretnéd a tréninget feldobni? A cégnek egy saját szabadulószoba lenne a megoldás? Amennyiben igen, vedd fel velünk a kapcsolatot és megtaláljuk a megoldást."
        buttonText="LÉPJ KAPCSOLATBA VELÜNK!"
        buttonHref="/kapcsolat"
      />
    </div>
  );
};

export default CompanyReferences;
